:root{
  --primary-color: #128C7E;
  --secundary-color: #FFF;
  --text-color-dark: #000;
  --text-color-light: #fff;
  --text-color: #262626;
  --font-family: 'Roboto', sans-serif;
  --icon-color: #F3F3F3;
}


main {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load {
  position: fixed;
  z-index: 999999;
  height: 100%;
  width: 100%;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background : white;
}

.gear {
  position: absolute;
  z-index: -10;
  width: 40px;
  height: 40px;
  animation: spin 5s infinite;
}

.two {
  left: 40px;
  width: 80px;
  height: 80px;
  animation: spin-reverse 5s infinite;
}

.three {
  top: 45px;
  left: -10px;
  width: 60px;
  height: 60px;
}
.d-flex{
  display: flex;
}
@keyframes spin {
  50% {
      transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  50% {
      transform: rotate(-360deg);
  }
}

.lil-circle {
  position: absolute;
  border-radius: 50%;
  box-shadow: inset 0 0 10px 2px gray, 0 0 50px white;
  width: 100px;
  height: 100px;
  opacity: .65;
}

.blur-circle {
  position: absolute;
  top: -19px;
  left: -19px;
}

.form-container{
  height: 100%;
  width: 100%;
  display: flex;
}
.form-button{
  width: 140px;
  height: 40px;
  border-style: none;
  border-radius: 8px;
  border: 2px solid var(--primary-color);
  background-color: transparent;
  font-size: 1rem;
  font-weight: 800;
  color: var(--primary-color);
  cursor: pointer;
  transition: .7s;
}

.form-button:hover{
  background-color: var(--primary-color);
  color: var(--secundary-color);
}

.form-button-light{
  border: 2px solid var(--secundary-color);
  color: var(--text-color-light);
}

@media (max-width:1023px) {
  .form-button{
      background-color: var(--primary-color);
      color: var(--secundary-color);
      border: 2px solid var(--secundary-color);
  }
}
.form-input-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.form-input{
  width: 300px;
  height: 40px;
  padding: 10px 20px;
  border-style: none;
  background-color: var(--icon-color);
}
.form-social{
  display: flex;
  gap: 24px;
}
.form-title{
  font-size: 3rem;
  font-weight: 400;
}
.form-link {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: inline;
  font-family: inherit;
  font-size: inherit; 
  text-align: left; 
  outline: none;
}
.form-link:hover, .form-link:focus {
  text-decoration: none;
}
.form-title-light{
  color: var(--text-color-light);
}
.form {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secundary-color);
  padding: 55px 0;
  transition: 1s;
}

.form-register{
  transform: translateX(-100%);
  z-index: -1;
  opacity: 0;
}

.login-container.move .form-login{
  transform: translateX(100%);
}

.login-container.move .form-register{
  transform: translateX(0%);
  z-index: 1;
  opacity: 1;
}

@media (max-width:1023px){
  .form{
      width: 100%;
      justify-content: space-evenly;
  }
  .form-register{
      position: absolute;
      background-color: var(--primary-color);
      color: var(--secundary-color);
      transform: translateX(100%);
  }
  .login-container.move .form-register{
      transform: translateX(0%);
  }

}
.login-container{
  position: relative;
  height: 599px;
  width: 1023px;
  border-radius: 12px;
  box-shadow: 0 5px 20px #A0A0A0;
  overflow: hidden;
}

@media (max-width:1023px){
  .login-container{
      width: 75vw;
      height: 75vh;
  }
}

@media (max-width:599px){
  .login-container{
      width: 100vw;
      height: 100vh;
      box-shadow: none;
      border-radius: 0px;
  }
}
.mobile-text{
  display: none;
}

@media (max-width:1023px){
  .mobile-text{
      display: block;
  }
  .mobile-text a {
      font-weight: 900;
      color: inherit;
  }
}
.overlay-container{
  position: absolute;
  top:0;
  left: 0;
  width: 50%;
  height: 100%;
  color: var(--text-color-light);
  text-align: center;
  transform: translateX(100%);
  z-index: 10;
  transition: 1s;
}

.login-container.move .overlay-container{
  transform: translateX(0%);
}

@media (max-width:1023px) {
  .overlay-container{
      display: none;
  }
}
.overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px;
  background-color: var(--primary-color);
  transition: 1s;
}

.login-container.move .overlay:nth-child(2) {
  z-index: -1;
  opacity: 0;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Red Hat Display', sans-serif;
}

a{
  text-decoration: none;
  color: var(--text-color-dark);
}

.social-icon{
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.2rem;
  background-color: var(--icon-color);
  box-shadow: 0 4px 4px #0004;
  transition: .5s;
  cursor: pointer;
}

.social-icon:hover{
  background-color: var(--primary-color);
  transform: translateY(-15%);
  color: var(--text-color-light);
}